import { Controller } from "@hotwired/stimulus";
import { establishRoomConnection } from '../channels/chat_channel'

export default class extends Controller {
  static targets = ["roomId", "closeId"]

  closeRoom() {
    let messangerStart = document.getElementById(`enclosed`)
    if(messangerStart) {
      messangerStart.innerHTML = ''
    }
  }
  
  openRoom() {
    let room_id = this.roomIdTarget.value
    let url = `/chat_g/rooms/${room_id}/small_room`
    this.load(url)
    establishRoomConnection(room_id)
  }

  openRoomIndex() {
    let close_id = this.closeIdTarget.value
    let url = `/chat_g/get_rooms`
    $(`#${close_id}`).removeClass("remove")
    let callback = function() { $(`#${close_id}`).addClass("remove"); console.log('callback') }
    this.load(url, callback)
  }

  load(url, callback) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        document.getElementById("enclosed").innerHTML = html
      }).then(()=>{
        if (typeof callback === 'function' && callback()) {
          callback()
        }
      })
  }

}