
import consumer from "./consumer"


function connectionExists(consumer, room_id) {
  let subs = consumer.subscriptions
  let isSubbed = false
  if(subs !== undefined && subs.subscriptions !== undefined && subs.subscriptions.length > 0) {
    let real_subs = subs.subscriptions
    let channels = []
    real_subs.every(sub => {
      let identifier = JSON.parse(sub.identifier)
      if(channels.includes(identifier) || ( identifier.channel === "ChatChannel" && parseInt(identifier.room) === parseInt(room_id) ) ) {
        isSubbed = true
        return false;
      }
      channels.push(identifier)
    });
  }
  return isSubbed
}

export function establishRoomConnection(room_id) {

  let isConnection = connectionExists(consumer, room_id)

  if(room_id && !(isConnection)) {
    consumer.subscriptions.create(
      { 
        channel: "ChatChannel", 
        room: room_id
      },

      {
        received(data) {
          let message_ele = $(document.getElementById(`message-section-${room_id}`))
          if(data.message !== '') {
            message_ele.append(`
              <div style="display: flex; flex-direction: column; margin-bottom: 15px;">
                <div style="width: 100%;"><div style="font-size: 10px;">${data.name}</div></div>
                <div style="width: 100%;">
                  <div class="chat-message-container" style="background-color: white; border-radius: 10px; padding: 10px; word-wrap: break-word; font-size: 16px;">
                    ${data.message}
                  </div>
                  <div style="width: 100%; margin-top: 5px;"><div style="border-bottom: 1px solid black;"></div></div>
                </div>
              </div>
            `)
          }
        }
      }
    )
  }
};
