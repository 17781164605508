import { Controller } from "@hotwired/stimulus";
import { establishRoomConnection } from '../channels/chat_channel'

export default class extends Controller {
  static targets = ["url", "enclosed"]

  close() {
    let enclosed = this.enclosedTarget.value
    if(messangerStart) {
      $(`#${enclosed}`).remove()
    }
  }

  connect() {
    let enclosed = this.enclosedTarget.value
    let url = this.urlTarget.value
    fetch(url)
      .then(response => response.text())
      .then(html => {
        $(`#${enclosed}`).after(html)
        $(`#${enclosed}`).html('')
      })
  }

}